import * as React from "react"
import { Link } from "gatsby"
import { menuItems } from "../../constants/links"
import { friendsItems } from "../../constants/friends"
import { FooterStyles, FooterMenuStyles, CopyrightStyles } from "./FooterStyles"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"
import { RiGatsbyLine } from "react-icons/ri"
import { AiOutlineHeart } from "react-icons/ai"

import {
  FaFacebookSquare as Facebook,
  FaTwitterSquare as Twitter,
  FaInstagram as Instagram,
  FaLinkedin as Linkedin,
} from "react-icons/fa"

const Footer = () => {
  /* const allProduct = useAllProduct() */
  const siteMeta = UseSiteMetadata()
  return (
    <FooterStyles style={{ marginBottom: 0 }} className="section">
      <div className="container container__tight">
        <FooterMenuStyles className="footer__menu">
          <h5>Links</h5>
          <ul>
            {menuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.path} activeClassName="menu__item--active">
                    {item.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </FooterMenuStyles>


        {siteMeta.twitterUsername ||
          siteMeta.facebookUsername ||
          siteMeta.instagramUsername ||
          siteMeta.linkedinUsername ? (
            <div className="footer__menu_wrapper">
              <FooterMenuStyles className="footer__menu social__menu">
                <h5>
                  Il Rifugio Cognolo sui social<span>.</span>
                </h5>
                <ul>
                  {/* {siteMeta.twitterUsername && (
                    <li>
                      <a
                        href={`https://www.twitter.com/${siteMeta.twitterUsername}`}
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                      >
                        <Twitter />
                      </a>
                    </li>
                  )} */}
                  {siteMeta.facebookUsername && (
                    <li>
                      <a
                        href="https://www.facebook.com/rifugio.cognolo"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                      >
                        <Facebook />
                      </a>
                    </li>
                  )}
                  {siteMeta.instagramUsername && (
                    <li>
                      <a
                        href="https://www.instagram.com/rifugio_cognolo/?hl=it"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                      >
                        <Instagram />
                      </a>
                    </li>
                  )}
                  {/* {siteMeta.linkedinUsername && (
                    <li>
                      <a
                        href={`https://www.linkedin.com/${siteMeta.linkedinUsername}`}
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                      >
                        <Linkedin />
                      </a>
                    </li>
                  )} */}
                </ul>
              </FooterMenuStyles>
              <FooterMenuStyles className="footer__menu social__menu">
                <h5>
                  Gli amici del Rifugio Cognolo<span>.</span>
                </h5>
                <ul>
                  {friendsItems.map((item, index) => {
                    return (
                      <li key={index} className="li-friend">
                        <Link to={item.path} >
                          {item.text}
                          <span>.</span>
                        </Link>
                      </li>
                    )
                  })}
                  
                </ul>
              </FooterMenuStyles>
            </div>
          ) : (
            ""
          )}
      </div>
      <CopyrightStyles>
        <div className="container container__tight">
          <p className="footerNote">
            Designed & developed by ,
            <a
              href={siteMeta.developerUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {siteMeta.developerName}
            </a>
            made with  <AiOutlineHeart className="footerIconNote" style={{ marginLeft: "3px" }} /> and  <RiGatsbyLine className="footerIconNote" style={{ marginLeft: "3px" }} />
            <span>.</span>
          </p>
        </div>
      </CopyrightStyles>
    </FooterStyles>
  )
}

export default Footer
