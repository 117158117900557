export const friendsItems = [
  {
    path: "https://www.lunaria.org/",
    text: "Associazione Lunaria",
  },
  {
    path: "http://www.ymcaparthenope.eu/",
    text: "YMCA Parthenope",
  },
  {
    path: "http://www.comunedicasperia.it/",
    text: "Comune di Casperia",
  },
  {
    path: "https://comunediroccantica.it/",
    text: "Comune di Roccantica",
  },
  {
    path: "https://www.viadifrancescolazio.it/",
    text: "Rete associativa Via di Francesco",
  },
  {
    path: "https://www.facebook.com/reteimpresesabina/",
    text: "Rete tra imprese della Sabina",
  },
  {
    path: "#",
    text: "Rifugio Roma",
  },
  {
    path: "http://www.etirviaggi.it/",
    text: "Etir Viaggi",
  },
  {
    path: "https://www.wwftravel.it/",
    text: "WWF Travel",
  },
  {
    path: "https://www.camminesploratori.com/",
    text: "I Camminesploratori",
  },
  {
    path: "https://www.asinazionale.it/",
    text: "ASI",
  },
  {
    path: "http://www.viagginaturaecultura.it/",
    text: "Fourseasons Natura e Cultura",
  },
  {
    path: "https://www.aigae.org/",
    text: "AIGAE",
  },
  {
    path: "https://www.lagap.org/",
    text: "LAGAP",
  },
  
]
