export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/chisiamo",
    text: "Chi Siamo",
  },
  {
    path: "/dove",
    text: "Come raggiungere il rifugio",
  },
  {
    path: "/accoglienza",
    text: "Accoglienza",
  },
  {
    path: "/attivita",
    text: "Attività",
  },
  {
    path: "/contatti",
    text: "Contatti",
  },
]
