import React, {useCallback, useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import MenuContext from "../MenuContext"
import { motion } from "framer-motion"
import { menuItems } from "./NavConstants"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"
import { StaticImage } from "gatsby-plugin-image"
import EventBus from "../Event/EventBus";

import {
  NavModuleStyles,
  NavTopLevel,
  SubNavStyles,
  HamburgerStyles,
  LogoStyles,
} from "./NavModuleStyles"
import {
  barOneVariants,
  barTwoVariants,
  barThreeVariants,
  menuList,
  subMenuNavVariants,
} from "./NavAnim"



const NavModule = () => {
  //const featuredProduct = useFeaturedProduct()

  const [isOpen, setNav] = useContext(MenuContext)
  const [background, setBackground] = useState(false)
  const [reApply, setReAppply] = useState(false)
  const [navbar, setNavbar] = useState(false)

  const toggleNav = function () {
    setNav((isOpen) => !isOpen)
    //console.log(background, "background")
  };


  const { title } = UseSiteMetadata()
  const changeBackground = useCallback((event) => {
   /*  console.log(event)
    console.log("changeBackground") */
    if (window.scrollY >= window.innerHeight) {
      setBackground(true)
    } else {
      setBackground(false)
    }
  }, [])

  useEffect(() => {
    //console.log("ONCahnge isOpen", isOpen, background)
    if (background && isOpen) {
      setBackground(false)
      //console.log("isOpen", reApply)
    }
    if (!isOpen) {
      
    }
    if (isOpen) {
      console.log("rimuovo evento")
      window.removeEventListener("scroll", changeBackground, false)
      //window.addEventListener("scroll", changeBackground)
    } else {
      console.log("aggiungo evento")
      window.addEventListener("scroll", changeBackground, false)
    }
  }, [isOpen]);


  const variantsBackground = {
    background_visible: {
      backgroundColor: "var(--primary)",
      paddingTop: 10,
      paddingBottom: 10,
      transition: { ease: "easeOut", delay: 0.3, duration: 0.3 }
    },
    background_hidden: {
      backgroundColor: "rgba(0 0 0 .0)",
      paddingTop: 70,
      paddingBottom: 0,
    },
  }

  const variantsLogoText = {
    text_visible: {
      /* marginRight: 0,*/
      x: 0,
      opacity: 1,
      transition: { delay: 0.7, ease: "easeOut", duration: 0.3 }
    },
    text_hidden: {
      /*  marginRight: -600,*/
      x: -400,
      opacity: 0,
      transition: {
        x: { delay: 0.5, },
        default: { delay: 0, ease: "easeOut", duration: 0.3 },
      },
    },
  }
  /* console.log("RENDER NAV", isOpen) */

  return (

    <NavModuleStyles>

      {/* {isOpen && <Helmet
        bodyAttributes={{
          class: 'menu-open'
        }}
      />} */}
      <motion.div
        initial={background ? "background_hidden" : "background_visible"}
        animate={background ? "background_visible" : "background_hidden"}
        variants={variantsBackground}
        className="nav"
      /* data-background={background}
      animate={{
        y: background ? -60 : 0,
        backgroundColor: background ? "#ffc400" : "transparent"
      }} */
      >
        <div className="container">
          <HamburgerStyles
            initial="closed"
            animate={
              isOpen ? "open" : "closed"
            }
            onClick={toggleNav}
            onKeyDown={toggleNav}
            aria-label={isOpen ? "Close Menu" : "Open Menu"}
            className={isOpen ? " open" : ""}

          >
            <motion.span
              className="bar"
              variants={barOneVariants}
            ></motion.span>
            <motion.span
              className="bar"
              variants={barTwoVariants}
            ></motion.span>
            <motion.span
              className="bar"
              variants={barThreeVariants}
            ></motion.span>
          </HamburgerStyles>

          {title && (
            <LogoStyles>
              <Link className="logoLink" to="/">
                {/* 
                <span>..</span> */}
                {/* {title} */}
                <motion.span
                  initial={background ? "text_hidden" : "text_visible"}
                  animate={background ? "text_visible" : "text_hidden"}
                  variants={variantsLogoText}
                >
                  <span className="logo_text">Rifugio Cognolo</span>
                </motion.span>
                <motion.div
                  className="img"

                  animate={{
                    height: background ? 30 : 55,
                    transition: { ease: "easeOut", duration: 0.3 }
                  }}
                >
                  <StaticImage
                    src="../../../static/logos/logo_congolo_shilouette.png"
                    alt="Logo Rifugio Cognolo"
                    layout="constrained"
                    objectFit="contain"
                    placeholder="tracedSVG"
                    className="logo_cognolo"
                  />
                </motion.div>


              </Link>
            </LogoStyles>
          )}
        </div>
      </motion.div>
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={menuList}
        transition={{ type: "ease", stiffness: 50, velocity: 50 }}
        className="menu"
      >
        <NavTopLevel>
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link
                onClick={toggleNav}
                onKeyDown={toggleNav}
                to={item.path}
                activeClassName="menu__item--active"
              >
                {item.text}
                <span>.</span>
              </Link>
            </li>
          ))}

        </NavTopLevel>
      </motion.div>

    </NavModuleStyles>
  )
}

export default NavModule
